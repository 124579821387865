import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { WebRoutes } from "consts/enpoints/web";

const BackToLogin = () => (
    <Box
        to={WebRoutes.Login.Index}
        component={Link}
        sx={{
            ml: 'auto',
            display: 'block',
            width: 'fit-content'
        }}
    >
        Back to login
    </Box>
);

export default BackToLogin;

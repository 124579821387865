import { useState } from "react";
import useCookieState from "hooks/useCookieState";
import type { TimeoutableActionProps } from "./types";

export default function useTimeoutable({
    request,
    render,
    timeoutSec,
    cookieKey,
    defaultValue = 0
}: TimeoutableActionProps) {
    const [isLoading, setLoading] = useState(false);
    const [allowResendCodeCounter, setAllowResendCodeCounter] = useCookieState({
        cookieKey,
        defaultValue
    });

    const onClick = () => {
        setLoading(true);

        request({
            onSuccess: () => setAllowResendCodeCounter(timeoutSec)
        })
        .finally(() => setLoading(false));
    };

    const onChange = (currentTimeout: number) => {
        if (currentTimeout > 0) {
            return;
        }

        setAllowResendCodeCounter(0, { strategy: 'remove' });
    };

    const renderChildren = (currentTimeout = 0) => render(currentTimeout)[Number(!!currentTimeout)];

    return {
        isLoading,
        timeout: Number(allowResendCodeCounter),
        renderChildren,
        onChange,
        onClick
    };
};

import { memo } from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const EMIPasswordRules = () => (
    <Tooltip
        arrow
        placement='top'
        title={(
            <>
                Password must contain:<br />
                • At least one uppercase letter (A-Z)<br />
                • At least one lowercase letter (a-z)<br />
                • At least one number (0-9)<br />
                • At least one special character (e.g., !, @, #, $)<br />
                • Minimum of 8 characters in length
            </>
        )}
    >
        <IconButton>
            <ErrorOutlineIcon />
        </IconButton>
    </Tooltip>
);

export default memo(EMIPasswordRules);

import { useNavigate } from "react-router-dom";
import { newPassword } from "features/auth/api";
import { useSimpleRequest } from "hooks/useRequest";
import type { ResetPassword } from "ui/forms/Auth";
import { WebRoutes } from "consts/enpoints/web";

export default function useChangePassword() {
    const navigate = useNavigate();
    const request = useSimpleRequest();

    return async ({ email, password, token }: ResetPassword.ResetPasswordCredentials) => {
        await request(() => newPassword({
            email,
            password,
            token
        }), {
            onSuccess: () => navigate(WebRoutes.Login.Index, {
                replace: true
            }),
            notifyOnSuccess: true
        });
    };
};

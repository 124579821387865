import { memo } from 'react';
import { PasswordTextField, type TextFieldProps } from "ui/atoms/TextField";
import EMIPasswordRules from './EMIPasswordRules';

const ResetPasswordField = (props: TextFieldProps) => (
    <PasswordTextField
        {...props}
        InputProps={{
            ...props.InputProps,
            startAdornment: (
                <EMIPasswordRules />
            )
        }}
    />
);

export default memo(ResetPasswordField);

import type { FormikValues } from "formik";

export type Credentials = FormikValues;

export type CredentialsProps = {
    readonly onSubmit: (values: Credentials) => Promise<void>;
};

export class FormField {
    public static Email = 'email';
}

import { memo, type ReactNode } from "react";
import type { LoadingButtonProps } from "@mui/lab/LoadingButton";
import { RESEND_OTP_TIMEOUT_SEC } from "consts/auth";
import { UserCredentials } from "features/auth/types";
import { TimeoutableAction } from "ui/widgets/Action";
import { SubmitButton } from "ui/forms/Auth";
import useSendResetLink from "./useSendResetLink";

type Props = Pick<UserCredentials, 'email'> & {
    readonly resendResetLinkTimeout?: number;
    readonly ActionProps?: LoadingButtonProps;
};

const ResendResetLink = ({
    email,
    ActionProps,
    resendResetLinkTimeout = RESEND_OTP_TIMEOUT_SEC
}: Props) => {
    const { handleSendResetLink } = useSendResetLink();

    const renderChildren = (currentTimeout?: number): [ReactNode, ReactNode] => [
        'Send Reset Link',
        `Send Reset Link (${currentTimeout}) sec`
    ];

    return (
        <TimeoutableAction
            cookieKey='resendResetLinkWithin'
            timeoutSec={resendResetLinkTimeout}
            request={handleSendResetLink({ email })}
            render={renderChildren}
            ActionComponent={SubmitButton}
            ActionProps={{
                ...ActionProps,
                variant: 'contained'
            }}
        />
    );
};

export default memo(ResendResetLink);

import { useEffect } from "react";
import { Event } from "consts/auth";
import { publish } from "util/custom-event";
import useAuthActions from "features/auth/useActions";
import { revokeAuthData } from "features/auth/helpers";
import type { AuthResponse } from "features/auth/types";
import type { Credentials } from "ui/forms/Auth";

export default function useLogin() {
    const { login } = useAuthActions();

    useEffect(revokeAuthData, []);

    return async ({ email, password }: Credentials.Credentials) => {
        const userCredentials = {
            email,
            password
        };

        const [request] = login(userCredentials);

        const response = (await request) as AuthResponse;

        publish(
            response.data.twoFactorAuth
                ? Event.Redirect2FA
                : Event.GetUser,
            {
                ...userCredentials,
                response
            }
        );
    };
};

import type { ID } from "types";
import { route } from "util/support";

export const enum WebRouteTypes {
    Root = 'Root',
    Dashboard = 'Dashboard',
    Login = 'Login',
    ChangePassword = 'ChangePassword',
    Transactions = 'Transactions',
    Finance = 'Finance',
    Merchants = 'Merchants',
    Users = 'Users',
    Consumers = 'Consumers',
    Profile = 'Profile',
    Support = 'Support'
};

export const WebRoutes = {
    [WebRouteTypes.Root]: route(['']),
    [WebRouteTypes.Dashboard]: route(['dashboard']),
    [WebRouteTypes.Login]: {
        Index: route(['login']),
        Verify2FA: route(['login', 'verify-2fa']),
        SendResetLink: route(['login', 'send-reset-link'])
    },
    [WebRouteTypes.ChangePassword]: route(['change-password', ':token']),
    [WebRouteTypes.Transactions]: route(['transactions']),
    [WebRouteTypes.Merchants]: {
        Index: route(['merchants']),
        List: route(['merchants', 'list']),
        KYCSettings: {
            Index: route(['merchants', 'kyc-settings']),
            Merchant: (merchantId: ID) =>
                route(['merchants', 'kyc-settings', merchantId])
        },
        TicketingSystem: {
            Index: route(['merchants', 'ticketing-system']),
            Merchant: (merchantId: ID) =>
                route(['merchants', 'ticketing-system', merchantId])
        }
    },
    [WebRouteTypes.Finance]: route(['finance']),
    [WebRouteTypes.Consumers]: {
        Index: route(['consumers']),
        List: route(['consumers', 'list']),
        ImportHistory: {
            Index: route(['consumers', 'import-history']),
        }
    },
    [WebRouteTypes.Users]: route(['users']),
    [WebRouteTypes.Profile]: route(['profile']),
    [WebRouteTypes.Support]: route(['support'])
};

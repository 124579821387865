import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { WebRoutes } from "consts/enpoints/web";
import useActions from "features/general/useActions";
import { NotificationSeverity } from "features/general/types";
import useReferrerStatus from "./useReferrerStatus";
import { ReferrerStatus } from "./types";

export default function useReferrerStatusMessaging() {
    const navigate = useNavigate();

    const status = useReferrerStatus();

    const actions = useActions();

    const helpers = {
        ...actions,
        navigate
    };

    const helpersRef = useRef(helpers);
    helpersRef.current = helpers;

    useEffect(() => {
        const { showNotication, navigate } = helpersRef.current;

        new Map()
            .set(ReferrerStatus.Expired, () =>
                showNotication({
                    message: 'It looks like the link you used is either invalid or has expired. For security reasons, password reset links are only valid for a limited time.',
                    severity: NotificationSeverity.Warning
                }))
            .set(ReferrerStatus.Failed, () =>
                showNotication({
                    message: 'Something went wrong. Marius will investigate this issue.',
                    severity: NotificationSeverity.Error
                }))
            .get(status)?.();

        navigate(WebRoutes.Login.Index, {
            replace: true
        });
    }, [status]);
};

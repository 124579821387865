import { memo } from 'react';
import Typography from "@mui/material/Typography";
import { SignIn } from 'ui/layouts/SignIn';
import { Credentials } from "ui/forms/Auth";
import useSendResetLink from './useSendResetLink';
import ResendResetLink from './ResendResetLink';
import { BackToLogin } from '../support';

const SendResetLink = () => (
    <SignIn
        title='Forgot Password'
    >
        <Typography
            sx={{
                color: 'text.secondary'
            }}
        >
            Please enter your email to reset password
        </Typography>
        <Credentials.Form
            onSubmit={(
                useSendResetLink()
                    .sendResetLink
            )}
            SubmitButton={({ form, SubmitButtonProps }) => (
                <ResendResetLink
                    ActionProps={{
                        ...SubmitButtonProps,
                        disabled: !form.isValid,
                        loading: form.isSubmitting
                    }}
                    email={form.values.email}
                />
            )}
        >
            {BackToLogin}
        </Credentials.Form>
    </SignIn>
);

export default memo(SendResetLink);

import { memo } from "react";
import { TimeoutableAction } from "ui/molecules/Action";
import useTimeoutable from "./useTimeoutable";
import type { TimeoutableActionProps } from "./types";

const Timeoutable = ({ ActionComponent, ActionProps, ...restProps }: TimeoutableActionProps) => {
    const {
        isLoading,
        timeout,
        onChange,
        onClick,
        renderChildren
    } = useTimeoutable(restProps);

    return (
        <TimeoutableAction
            timeout={timeout}
            onChange={onChange}
            renderChildren={renderChildren}
            ActionComponent={ActionComponent}
            ActionProps={{
                variant: 'text',
                ...ActionProps,
                onClick,
                disabled: ActionProps?.disabled || (timeout > 0),
                loading: ActionProps?.loading || isLoading
            }}
        />
    );
};

export default memo(Timeoutable);

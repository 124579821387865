import { memo } from "react";
import { DesktopDatePicker, type DesktopDatePickerProps } from '@mui/x-date-pickers/DesktopDatePicker';
import IconButton from "@mui/material/IconButton";
import ClearIcon from '@mui/icons-material/Clear';
import type { AbstractActionableFilterProps } from "ui/widgets/Filters/types";
import { Moment } from "moment";
import TextField, { type TextFieldProps } from "ui/atoms/TextField";
import { UiDateTimeFormatter } from "util/formaters";

export interface DatePickerFilterProps extends Omit<DesktopDatePickerProps<Moment, Moment>, 'onChange'>, AbstractActionableFilterProps {

}

const DatePickerFilter = ({
    onChange,
    id,
    ...props
}: DatePickerFilterProps) => {
    const defaultInputProps = {
        autoComplete: 'false'
    };

    const onMouseDown = () => {
        ((document.getElementById(id)
            ?.previousElementSibling as HTMLElement)
            ?.firstElementChild as HTMLElement)
            ?.click();
    };

    const handleChange = (date: Moment | null) => {
        onChange({
            type: id,
            payload: date
        });
    };

    const onClear = () => {
        const resetResult: any = null;
        onChange({
            type: id,
            payload: resetResult
        });
    };

    const datePickerDefaultProps: Partial<DesktopDatePickerProps<Moment, Moment>> = {
        inputFormat: UiDateTimeFormatter.Ui,
        renderInput: (params) => (
            <TextField
                sx={{
                    width: 211,
                    '& .MuiIconButton-root': {
                        marginLeft: -1,
                        marginRight: 1,
                        '& .MuiSvgIcon-root': {
                            marginRight: 0
                        }
                    }
                }}
                {...defaultInputProps}
                {...params as TextFieldProps}
                InputProps={{
                    endAdornment: (
                        <>
                            {Boolean(props.value) && (
                                <IconButton
                                    size='small'
                                    onClick={onClear}
                                >
                                    <ClearIcon
                                        sx={{ marginRight: '0 !important' }}
                                        fontSize='small'
                                    />
                                </IconButton>
                            )}
                        </>
                    ),
                    startAdornment: params.InputProps?.endAdornment
                }}
                onMouseDown={onMouseDown}
                id={id}
            />
        )
    };

    return (
        <DesktopDatePicker
            {...datePickerDefaultProps}
            {...props}
            onChange={handleChange}
        />
    );
};

export default memo(DatePickerFilter);
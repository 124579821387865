import { object, string, ref } from "yup";
import { REGEXP } from "consts/validation";
import { FormField } from "./types";
import { useValidationSchema as useCredentialsValidationSchema } from "../Credentials";

const useValidationSchema = () =>
    useCredentialsValidationSchema()
        .concat(
            object().shape({
                [FormField.Password]: string()
                    .min(8)
                    .matches(REGEXP.Uppercase, 'At least one uppercase letter (A-Z)')
                    .matches(REGEXP.Lowercase, 'At least one lowercase letter (a-z)')
                    .matches(REGEXP.Numbers, 'At least one number (0-9)')
                    .matches(REGEXP.SpecialChars, 'At least one special character (e.g., !, @, #, $)')
                    .required()
                    .label('Password'),

                [FormField.ConfirmPassword]: string()
                    .oneOf([ref(FormField.Password), null], 'Passwords don’t match. Try again')
                    .required()
                    .label('Confirm Password')
            })
        );

export default useValidationSchema;

import { memo } from "react";
import Box from "@mui/material/Box";
import { ClipboardTextField } from "ui/molecules/Input";
import Typography from "@mui/material/Typography";
import useFinancialDetails from "./useFinancialDetails";
import AccountSelector from "./AccountSelector";
import { selectSettings } from "features/general/selectors";
import { useTypedSelector } from "hooks";
import ProgressLoader from "ui/atoms/ProgressLoader";

const FinancialDetails = () => {
    const { account, hasFinancialDetails, isAccountsLoading } = useFinancialDetails();
    const settings = useTypedSelector(selectSettings);
    console.log(settings)

    return (
        <ProgressLoader
            isLoading={isAccountsLoading}
            WrapperComponent={Box}
            WrapperComponentProps={{
                sx: {
                    display: 'grid',
                    placeContent: 'center',
                    height: 'calc(100vh - 422px)'
                }
            }}
        >
            <Box
                sx={{
                    display: 'grid',
                    maxWidth: 500,
                    alignItems: 'flex-start',
                    gap: 3
                }}
            >{
                hasFinancialDetails() ?
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr',
                                gap: 3,
                            }}
                        >
                            <AccountSelector />
                        </Box>
                        :
                        <Typography
                            variant="body2"
                            sx={{
                                fontWeight: 600
                            }}
                        >
                            Currently, no financial details are displayed in your profile.
                            Rest assured, your payouts are not affected and will be processed
                            as usual.
                            For any updates or inquiries regarding your financial details,
                            please contact our financial team.
                        </Typography>
                }

                {
                    account?.iban && (
                        <>
                            <ClipboardTextField
                                variant='outlined'
                                label='Account Holder Name'
                                size='small'
                                fullWidth
                                value={account?.merchantName}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                            <ClipboardTextField
                                variant='outlined'
                                label='IBAN'
                                size='small'
                                fullWidth
                                value={account?.iban}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                            <ClipboardTextField
                                variant='outlined'
                                label='SWIFT'
                                size='small'
                                fullWidth
                                value={account?.swift}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                            <ClipboardTextField
                                variant='outlined'
                                label='VAT Number'
                                size='small'
                                fullWidth
                                value={account?.vat}
                                inputProps={{
                                    readOnly: true
                                }}
                            />

                            <Typography>
                                Additional details
                            </Typography>
                            <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(2, 1fr)',
                                gap: 3,
                            }}
                        >
                          
                      
                            <ClipboardTextField
                                variant='outlined'
                                label='Payout frequency'
                                size='small'
                                fullWidth
                                value={settings?.payoutCycle}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                            <ClipboardTextField
                                variant='outlined'
                                label='Rolling Reserve Cap'
                                size='small'
                                fullWidth
                                value={settings?.rollingReserveCap}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                              </Box>
                        </>)}

                {
                    account?.address && (
                        <>
                            <ClipboardTextField
                                variant='outlined'
                                label='Address'
                                size='small'
                                fullWidth
                                value={account?.address}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                            <ClipboardTextField
                                variant='outlined'
                                label='Currency'
                                size='small'
                                fullWidth
                                value={account?.currency}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                            <ClipboardTextField
                                variant='outlined'
                                label='Chain used'
                                size='small'
                                fullWidth
                                value={account?.chain}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </>
                    )
                }
            </Box>
        </ProgressLoader>
    );
};

export default memo(FinancialDetails);

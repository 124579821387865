import { recover } from "features/auth/api";
import { useSimpleRequest, type UseSimpleRequestReturnCallbackArg } from "hooks/useRequest";
import type { Credentials } from "ui/forms/Auth";

export default function useSendResetLink(settings?: UseSimpleRequestReturnCallbackArg) {
    const request = useSimpleRequest();

    const sendResetLink = async ({ email }: Credentials.Credentials) => {
        await request(() => recover({ email }), {
            ...settings,
            notifyOnSuccess: true
        });
    };

    const handleSendResetLink = ({ email }: Credentials.Credentials) =>
        (settings: UseSimpleRequestReturnCallbackArg) =>
            request(() => recover({ email }), {
                ...settings,
                notifyOnSuccess: true
            });

    return {
        sendResetLink,
        handleSendResetLink
    };
};

import { memo } from "react";
import Screen from "ui/layouts/Screen";
import { SendResetLink } from "ui/organizms/Auth";

const SendResetPasswordLink = () => (
    <Screen
        title='Forgot Password'
    >
        <SendResetLink />
    </Screen>
);

export default memo(SendResetPasswordLink);

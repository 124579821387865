import { memo } from "react";
import Box from "@mui/material/Box";
import type { LinkProps } from "./types";

const Link = ({ mailable, href, ...props }: LinkProps) => (
    <Box
        {...props}
        href={mailable ? `mailto:${href}` : href}
        component='a'
    />
);

export default memo(Link);

import { useMemo } from "react";
import { object, string } from "yup";
import { FormField } from "./types";

export const useValidationSchema = () =>
    useMemo(() => object().shape({
        [FormField.Email]: string()
            .email()
            .required()
            .label('Email')
    }), []);

export default useValidationSchema;

import { memo } from "react";
import Screen from "ui/layouts/Screen";
import { ChangePassword } from "ui/organizms/Auth";

const ChangePasswordScreen = () => (
    <Screen
        title='Change Password'
    >
        <ChangePassword />
    </Screen>
);

export default memo(ChangePasswordScreen);

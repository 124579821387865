import { memo, type PropsWithChildren } from "react";
import { useParams, useSearchParams } from 'react-router-dom';
import { getFormInputProps } from "util/forms";
import { PasswordTextField } from "ui/atoms/TextField";
import ResetPasswordField from "./ResetPasswordField";
import { FormField } from "./types";
import Credentials, { type CredentialsProps } from "../Credentials";
import useValidationSchema from "./useValidationSchema";

const ResetPassword = ({ children, ...restProps }: PropsWithChildren<CredentialsProps>) => {
    const { token } = useParams();
    const [searchParams] = useSearchParams();

    return (
        <Credentials
            {...restProps}
            validationSchema={useValidationSchema()}
            TextFieldProps={{
                disabled: true
            }}
            SubmitButtonProps={{
                children: 'Reset Password'
            }}
            initialValues={{
                [FormField.Email]: searchParams.get('email'),
                [FormField.Password]: '',
                [FormField.ConfirmPassword]: '',
                [FormField.ResetPasswordToken]: token
            }}
        >
            {form => (
                <>
                    <ResetPasswordField
                        {...getFormInputProps(FormField.Password, form)}
                        name={FormField.Password}
                        id={FormField.Password}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    <PasswordTextField
                        {...getFormInputProps(FormField.ConfirmPassword, form)}
                        label='Confirm Password'
                        name={FormField.ConfirmPassword}
                        id={FormField.ConfirmPassword}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    {children}
                </>
            )}
        </Credentials>
    );
};

export default memo(ResetPassword);

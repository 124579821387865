import { memo } from "react";
import Screen from "ui/layouts/Screen";
// TODO: Renalda, please import the Verify2FA component once you replicated from Management-BO
// import { Verify2FA } from "ui/organizms/Auth";

const Verify2FAScreen = () => (
    <Screen
        title='2FA Verification'
    >
        {/* <Verify2FA /> */}
    </Screen>
);

export default memo(Verify2FAScreen);

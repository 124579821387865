import { memo, useState, useEffect, useCallback, ChangeEvent } from 'react';
import TextField, { type TextFieldProps } from 'ui/atoms/TextField';
import Tooltip from '@mui/material/Tooltip';

type Props = Omit<TextFieldProps, 'onChange'> & {
    readonly validator: (value: number) => string;
    readonly onChange: (
        value: number | string,
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void;
};

const TextFieldInput = ({ value = '', onChange, validator, ...restProps }: Props) => {
    const [tooltipMessage, setTooltipMessage] = useState('');
    const [textFieldValue, setTextFieldValue] = useState(value);

    const validateInputValue = useCallback((value: string) => validator(Number(value)), [validator]);

    useEffect(() => {
        setTextFieldValue(value);
    }, [value]);

    useEffect(() => {
        if (!textFieldValue) {
            return;
        }

        const validationPayload = validateInputValue(`${textFieldValue}`);

        setTooltipMessage(validationPayload);
    }, [
        textFieldValue,
        validateInputValue
    ]);

    const handleChange: TextFieldProps['onChange'] = event => {
        const value = event.target.value.trim();

        if (value.length && Number.isNaN(Number(value))) {
            return;
        }

        setTextFieldValue(value);

        if (validateInputValue(value)) {
            return;
        }

        onChange(value, event);
    };

    return (
        <Tooltip
            arrow
            open={Boolean(tooltipMessage)}
            title={tooltipMessage}
            classes={{
                tooltip: 'validation-tooltip'
            }}
        >
            <TextField
                {...restProps}
                inputProps={{
                    maxLength: 5,
                    ...restProps.inputProps
                }}
                value={textFieldValue}
                onChange={handleChange}
                onBlur={() => !textFieldValue && setTooltipMessage('')}
            />
        </Tooltip>
    );
};

export default memo(TextFieldInput);

import { memo } from "react";
import {
    DateRange,
    DateRangePicker,
    type DateRangePickerProps
} from '@mui/x-date-pickers-pro/DateRangePicker';
import type { Moment } from "moment";
import Stack from "@mui/material/Stack";
import TextField, { type TextFieldProps } from "ui/atoms/TextField";
import type { AbstractActionableFilterProps } from "ui/widgets/Filters/types";
import { UiDateTimeFormatter } from "util/formaters";
export interface DateRangeFilterProps extends Omit<
    DateRangePickerProps<Moment, Moment>,
    'onChange' | 'renderInput'
>, AbstractActionableFilterProps {
    readonly startProps?: TextFieldProps;
    readonly endProps?: TextFieldProps;
    readonly renderInput?: DateRangePickerProps<Moment, Moment>['renderInput'];
}

const DateRangeFilter = ({
    onChange,
    id,
    startProps: fromDate = {},
    endProps: toDate = {},
    ...props
}: DateRangeFilterProps) => {
    const defaultInputProps = {
        sx: { width: 211 },
        autoComplete: 'false'
    };

    const handleChange: DateRangePickerProps<Moment, Moment>['onChange'] = (date: DateRange<Moment>) =>
        onChange({
            type: id,
            payload: date
        });

    return (
        <DateRangePicker
            className='DateRangePicker'
            disableFuture
            inputFormat={UiDateTimeFormatter.Ui}
            {...props}
            renderInput={(startProps, endProps) => (
                <Stack direction="row" spacing={2}>
                    <TextField
                        {...defaultInputProps}
                        {...startProps as TextFieldProps}
                        {...fromDate}
                    />
                    <TextField
                        {...defaultInputProps}
                        {...endProps as TextFieldProps}
                        {...toDate}
                    />
                </Stack>
            )}
            onChange={handleChange}
        />
    );
};

export default memo(DateRangeFilter);

/*export class DaterangeValueObject extends FilterValueObject<DateRange<Moment>, Moment | null> {
    // public toSerialized(environment?: Environment): string[] {
    //     const serializedValue = this.value
    //         .map(this.setTimeOfDate.bind(this))
    //         .map(date => date?.format(this.getEnvironmentFormatter(environment)) ?? null);

    //     if (serializedValue.every(date => !date)) {
    //         return [];
    //     }

    //     return serializedValue.map(String);
    // }

    public serialize(value: Moment | null, environment?: Environment | undefined): string {
        return String(value?.format(this.getEnvironmentFormatter(environment)) ?? null);
    }

    public toArray() {
        const dateRange = this.value
            .map(this.setTimeOfDate.bind(this))
            .map(date => date?.isValid() ? date : null);

        if (dateRange.every(date => !date)) {
            return [] as unknown as DateRange<Moment>;
        }

        return dateRange as DateRange<Moment>;
    }

    public isEmpty(_: DateRange<Moment> | Moment): boolean {
        return false;
    }

    private getEnvironmentFormatter(environment = Environment.Browser): string {
        return {
            [Environment.Browser]: DATE_FORMAT,
            [Environment.Server]: DATE_FORMAT
        }[environment];
    }

    private setTimeOfDate(date: Moment | null, modifier: number) {
        if (modifier <= 0) {
            return date?.startOf('day');
        }

        return date?.endOf('day');
    }
}

export const DaterangeValueObjectStrategy = {
    [ValueObjectStrategy.SearchQuery](values: Array<string>, meta?: DOMStringMap) {
        const initialValueStrategyName = (meta?.initialValueStrategy as ValueStrategy) ||
            ValueStrategy.Default;

        return new DaterangeValueObject(
            filterValueStrategy[FilterVariant.DateRange][initialValueStrategyName]!.getInitialValue(values)
        );
    },

    [ValueObjectStrategy.Change](values: DateRange<Moment>, meta?: DOMStringMap) {
        const changeValueStrategyName = (meta?.changeValueStrategy as ValueStrategy) ||
            ValueStrategy.Default;

        return new DaterangeValueObject(
            filterValueStrategy[FilterVariant.DateRange][changeValueStrategyName]!.handleChangeValue(values)
        );
    },

    getDefaultValue(meta?: DOMStringMap) {
        const defaultValueStrategyName = (meta?.defaultValueStrategy as ValueStrategy) ||
            ValueStrategy.Default;

        return filterValueStrategy[FilterVariant.DateRange][defaultValueStrategyName]!.getDefaultValue();
    }
};*/

import { memo, type PropsWithChildren } from "react";
import { getFormInputProps } from "util/forms";
import { PasswordTextField } from "ui/atoms/TextField";
import { FormField } from "./types";
import Credentials, { type CredentialsProps } from "../Credentials";

const SignIn = ({ children, ...restProps }: PropsWithChildren<CredentialsProps>) => (
    <Credentials
        {...restProps}
        SubmitButtonProps={{
            children: 'Login'
        }}
        initialValues={{
            [FormField.Password]: ''
        }}
    >
        {form => (
            <>
                <PasswordTextField
                    {...getFormInputProps(FormField.Password, form)}
                    name={FormField.Password}
                    id={FormField.Password}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                />
                {children}
            </>
        )}
    </Credentials>
);

export default memo(SignIn);

import type { ComponentType } from "react";
import useReferrerStatusMessaging from "./useReferrerStatusMessaging";

export const withReferrerStatusMessaging = <TProps extends object>(Component: ComponentType<TProps>) =>
    (props: TProps) => {
        useReferrerStatusMessaging();

        return (
            <Component
                {...props}
            />
        );
    };

export default withReferrerStatusMessaging;

import { memo } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { WebRoutes } from 'consts/enpoints/web';
import { SignIn as SignInLayout } from 'ui/layouts/SignIn';
import { SignIn } from "ui/forms/Auth";
import useLogin from './useLogin';

const Login = () => (
    <SignInLayout
        title='Sign in to Macropay'
    >
        <SignIn.Form
            onSubmit={useLogin()}
        >
            <Box
                to={WebRoutes.Login.SendResetLink}
                component={Link}
                sx={{
                    ml: 'auto',
                    display: 'block',
                    width: 'fit-content'
                }}
            >
                Forgot password?
            </Box>
        </SignIn.Form>
    </SignInLayout>
);

export default memo(Login);

import { memo } from "react";
import Typography from "@mui/material/Typography";
import { ResetPassword } from "ui/forms/Auth";
import { SignIn } from "ui/layouts/SignIn";
import { BackToLogin } from "../support";
import useChangePassword from "./useChangePassword";

const ChangePassword = () => (
    <SignIn
        title='Set a new password'
    >
        <Typography
            sx={{
                color: 'text.secondary'
            }}
        >
            Please enter your new password
        </Typography>
        <ResetPassword.Form
            onSubmit={useChangePassword()}
        >
            <BackToLogin />
        </ResetPassword.Form>
    </SignIn>
);

export default memo(ChangePassword);
